import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import { GatsbyImage } from "gatsby-plugin-image";
import FsLightbox from "fslightbox-react";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Extra({ videos = [], images = [] }) {
  const defaultState = {
    isOpen: false,
    slide: 0,
  };
  const [state, setState] = useState(defaultState);

  function handleClick(slide) {
    setState({
      isOpen: !state.isOpen,
      slide,
    });
  }

  return (
    <>
      <Carousel responsive={responsive} swipeable={false} draggable={false}>
        {videos.map((video, index) => (
          <div key={index} className="p-3">
            <GatsbyImage
              className="cursor-pointer"
              image={video.gatsbyImageData}
              alt={""}
              onClick={() => handleClick(index + 1)}
            />
          </div>
        ))}
        {images.map((image, index) => (
          <div key={index} className="p-3">
            <GatsbyImage
              className="cursor-pointer"
              image={image.croped}
              alt={""}
              onClick={() => handleClick(videos.length + index + 1)}
            />
          </div>
        ))}
      </Carousel>
      <FsLightbox
        toggler={state.isOpen}
        slide={state.slide}
        sources={[
          ...videos.map(
            (video) => `https://www.youtube.com/watch?v=${video.alt}`
          ),
          ...images.map((image) => image.url),
        ]}
      />
    </>
  );
}

export default Extra;
